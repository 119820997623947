@import '../../styles/media-queries.scss';
@import '../../styles/variables.scss';

.legal-container {
  padding: 2rem 2rem 2rem 4rem;

  .myUplink-policy {
    display: flex;
  }
  .myUplink-policy-link {
    margin-right: 20px;
  }
  .myUplink-policy-link,
  a {
    color: $policy-link-color;
    font-weight: 600;
  }
  .myUplinkPro-policy-link {
    color: $black;
    font-weight: normal;
    text-decoration: none;
  }
  .myUplink-policy-link,
  .myUplinkPro-policy-link {
    font-size: 1rem;
  }

  @media only screen and (max-width: $smallDesktop) {
    margin: 3%;
  }

  tr {
    td:first-of-type {
      padding-right: 10px;
    }

    td {
      vertical-align: top;
      padding: 5px;
      font-size: 16px;
    }
  }

  span {
    font-size: 14px;
  }
}
