@import '../../styles/media-queries.scss';

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

.inputComponent {
  position: relative;

  .inputComponent__placeholder {
    &__label {
      position: relative;
      margin: 35px 0 0 0 !important;
      width: 100%;
      pointer-events: all;
      line-height: 1em;
    }
  }

  &__field {
    width: 100%;
    min-width: 300px;
    padding: 0.6em 0.8em;
    font-size: 1.5em;
    font-weight: 400;
    border: 2px solid #e2e2e2;
    border-radius: 3px;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #414042;

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
    }

    &:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }

    &--error {
      border: 2px solid #a83131;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &__label {
    margin-bottom: 0px;

    &--left {
      margin-left: 10px;
      float: left;
    }
  }

  &__placeholder {
    left: 0;
    color: #7d7c7c;
    padding: 1.1em 0 0 1.1em;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition:
      font-size 200ms,
      padding 200ms;
    z-index: 1;
    font-size: 1.2em;

    &.focused {
      font-size: 0.625em;
      padding: 0 0 0 2em;
    }
  }

  &__error {
    color: #a83131;
    white-space: pre-wrap;
    margin: 7.7px 0 7.7px 0;
    text-align: left;
    font-weight: 400;
  }

  &--has-error {
    .inputComponent__placeholder__label {
      margin: 0 0 0 0;
    }
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }

  #fromEmail {
    cursor: not-allowed;
  }

  @media only screen and (max-width: $tablet) {
    &__field {
      font-size: 1em;
    }

    &__error {
      font-size: 0.75em;
    }

    &__placeholder {
      padding: 0.5em 1em;
    }
  }

  @media screen and (max-width: $mobile) {
    &__error {
      font-size: 0.75em;
    }
  }
}
