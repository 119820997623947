@mixin dropdown($dropdown-bg-color: white, $dropdown-color: black) {
  width: 100%;

  input {
    width: 100%;
    padding-right: 4rem;
    text-overflow: ellipsis;
    cursor: pointer;

    &::placeholder {
      color: gray;
    }
  }

  &-button {
    width: 100%;
    min-width: 300px;
    padding: 0.6em 0.8em !important;
    border-radius: 3px !important;

    .caret {
      bottom: 20px !important;
    }
  }

  &-input button {
    pointer-events: none;
  }

  & button {
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    padding: 0;
    color: $dropdown-color;
    position: relative;
    font-size: 14px;
    border-color: transparent;

    .caret {
      position: absolute;
      right: 2rem;
      bottom: 24px;
    }

    &:hover {
      background-color: transparent;
      color: #382f2f;
      border-color: transparent;
    }
  }

  & .dropdown-menu {
    background-color: $dropdown-bg-color;
    top: 85%;
    margin: 0;
    border: 0;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    & li {
      padding: 1.5rem 0;
      margin: 0 2rem;
      border-top: 1px $dropdown-color solid;

      & a {
        color: $dropdown-color;
        padding-left: 0;
        white-space: normal;

        &:hover,
        &:focus {
          background-color: $dropdown-bg-color;
        }
        &:focus {
          padding-left: 10px;
        }
      }
    }

    &:empty {
      display: none;
    }
  }
}

.btn-group.open .dropdown-toggle,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  box-shadow: none !important;
  background-color: transparent !important;
}

.tile-property-selector {
  .dropdown .border {
    border: 2px solid #ccc !important;
    margin-top: 0 !important;
    background-color: #fff;
  }
  .dropdown button {
    text-align: left;
  }

  .btn-group.open .dropdown-toggle,
  .btn-default:focus,
  .btn-default.focus {
    background-color: #fff !important;
  }
}

.dropdown {
  @include dropdown();
  line-height: 1.7;
}
