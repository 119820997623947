@import '../../styles/mixins.scss';
@import '../../styles/media-queries.scss';
$title-color: #ff5a00;

.profile-page {
  .text {
    font-size: 1.3em;
  }

  padding: 0 2rem 50px 2rem;

  & ul.dropdown-menu {
    max-height: 20rem;
  }

  & .button {
    display: inline-block;
    min-width: 150px;
  }

  & .profile-info {
    margin-bottom: 20px;
  }

  .address.buttonWrapper {
    padding-bottom: 0;
  }

  float: left;
  width: 100%;

  & .label {
    color: #414042;
    font-size: 1.3em;
    padding-left: 0;
    padding-bottom: 0;
  }
  & .value {
    line-height: 1.3em;
    font-size: 1.3em;
    display: inline-block;
    margin-bottom: 1.8rem;
  }
  & .info-item {
    margin-bottom: 13px;
  }
  & .info-item.address {
    margin-bottom: 25px;
  }
  & .address {
    .value {
      display: block;
      line-height: 1.4em;
    }
  }
  & ul.dropdown-menu {
    margin-top: 8px;
  }

  .address-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .inputComponent {
      margin-right: 20px;
      width: 100%;
    }

    .dropdown {
      margin-right: 20px;
      line-height: 1.4em;

      .inputComponent {
        margin-right: 0;
      }
    }
  }

  .form--half {
    width: 100%;
    max-width: 450px;
  }

  .tabordion {
    margin-top: 0px;
  }

  .profile-section {
    margin-top: 35px;
  }

  .inputComponent--has-error {
    margin: 0;
  }

  .inputComponent__error {
    top: 0;
    position: relative;
    margin: 7.7px 0 4.5px 0;
  }

  @media (max-width: 533px) {
    h1 {
      width: 100%;
    }

    .buttonWrapper {
      margin-top: 0;
    }
  }

  .success-label {
    margin: -10px 20px 5px;
    font-size: 1.2em;
    font-weight: 500;
    color: green;
  }

  .confirmation-label {
    margin: -10px 20px 5px;
    font-size: 1.2em;
    font-weight: 500;
  }

  input[disabled] {
    color: #aaa;
    background: #f5f5f5;
  }

  button#tzId {
    margin: 0;
    padding: 0;
    line-height: 2.05;
  }

  @media (max-width: 768px) {
    .tab-content .success-label,
    .text-danger {
      margin-left: 10px;
    }
  }

  @media (min-width: 1366px) {
    h1 {
      width: 70%;
    }
  }

  @media (max-width: 540px) {
    .tab-content .success-label,
    .text-danger {
      margin-left: 20px;
    }
  }
}

.remove-user-account {
  font-weight: 700;
  font-size: 1.2em;
  margin-top: 35px;
}

.remove-confirmation-popup {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
  text-align: center;

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 17px;
  }
  h4 {
    margin: 20px 0 -20px 0;
  }
  .popup-body {
    font-size: 1.5em;
    font-weight: 300;
  }
  .popup-bottom-panel {
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
  }
}
.remove-confirmation-popup > * {
  margin: 4px;
  width: 100%;
}

.remove-confirmation-popup .popup-bottom-panel .btn:not(:first-child) {
  margin-left: 12px;
}

.editProfileInfo {
  &__border {
    box-sizing: border-box;
    height: 1px;
    width: 756px;
    border: 0.5px solid #414042;
    margin-top: 30px;
  }

  &__savedMessage {
    font-size: 20px;
    color: royalblue;
    margin-left: 40px;
    margin-top: -20px;
  }

  .inputComponent {
    &__field {
      width: 50%;
      min-width: 370px;
    }
  }
}
