@import '../../../../styles/mixins.scss';
@import '../../../../styles/media-queries.scss';

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-left: 2%;

  &-list {
    padding-left: 0;
    margin-bottom: 0;
  }

  &-item {
    list-style-type: none;
    display: inline-block;
    margin-left: 3rem;
    border-radius: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid transparent;
    margin-top: 0.5rem;
    transition: all 0.3s ease;
    width: auto;

    &.active {
      border: 1px solid #808080;
    }

    &:hover {
      border: 1px solid #808080;
    }

    a {
      @include label();
      color: #414042;
      padding: 1.4rem;
      display: block;
      height: 40px;
      border-radius: 1.5rem;
      line-height: 0;
      font-size: 18px;
      font-weight: 300;
      font-family:
        Roboto,
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;

      &:first-of-kind {
        margin-left: 0;
      }

      &:focus,
      &:hover {
        color: #414042;
        text-decoration: none;
      }
    }
  }
}

@media all and (max-width: $tablet) {
  .navigation {
    flex-direction: column;

    &-item {
      margin-left: 1rem;
      margin-bottom: 1rem;
      border-radius: 2.5rem;

      a {
        line-height: 0.8rem;
        font-size: 1.8rem;
      }
    }

    &-list {
      width: 100%;
      text-align: center;
    }
  }
}
