.InputWithCopy {
  display: flex;

  & .copyBtn {
    display: inline-block;
    margin: 0 8px 0 0;
    font-family:
      Roboto,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 16px;
    border: 0;
    color: #fff;
    cursor: pointer;
    outline: 0;
  }
  .copyBtn:hover {
    opacity: 0.7;
  }

  & .hideBtn {
    display: inline-block;
    margin: 0 8px 0 0;
    font-family:
      Roboto,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 16px;
    border: 0;
    color: #fff;
    cursor: pointer;
    outline: 0;
  }
  .hideBtn:hover {
    opacity: 0.7;
  }
}

.inputWithCopyComponent {
  display: flex;
  position: relative;
  width: 100%;

  .inputWithCopyComponent__placeholder__label {
    position: relative;
    width: 100%;
    margin: 0;
  }

  &__field {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    font-weight: 300;
    border: 2px solid #e2e2e2;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__placeholder {
    left: 0;
    color: #2d2d2d;
    padding: 1.2em 0 0 1.5em;
    pointer-events: none;
    position: absolute;
    top: 0;
    font-weight: 300;
    transition:
      font-size 200ms,
      padding 200ms;
    z-index: 1;
    font-size: 16px;

    &.focused {
      color: #999;
      font-size: 0.625rem;
      padding-top: 0;
      padding-left: 1rem;
    }
  }
}
