@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.signed-out-layout-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  background-image: linear-gradient(90deg, #dfdfdf 55%, #fff 45%);

  .welcome-page {
    display: flex;
    flex-direction: column;
  }

  &__contentWrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: auto;
    width: 50%;
    text-align: center;
  }

  &__slideshow {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58%;
    height: 100vh;
    padding: 3em;
  }

  &__formWrapper {
    order: 2;
    width: 42%;
    height: 100vh;
    background-color: white;
    padding: 4em;
  }

  &__formWrapperContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__menuWrapper {
    width: calc(100% - 4rem);
    text-align: left;
    margin-bottom: 24px;
  }

  &__menuLink {
    @include label();
    font-size: 0.85rem;
    text-decoration: none;
    font-weight: 400;
    margin-right: 1rem;
    color: #7d7c7c;
    display: inline-block;

    &:first-of-kind {
      margin-left: 0;
    }

    &--active {
      opacity: 1;
      font-weight: 400;
      text-decoration: underline;
    }

    &:hover {
      color: #7d7c7c;
    }
  }
}

.welcome-content {
  display: flex;
  flex-grow: 1;
}

.aboutApp-page {
  flex-grow: 1;
  background-color: $font-color-footer;
  &-header {
    display: flex;
    align-items: center;
    height: 86px;
    padding: 0.625rem 0.625rem 0.625rem 4rem;
    background-color: $font-color-footer;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  .legal-container {
    padding: 0;
  }
  .content {
    padding: 2rem 3rem 2rem 4rem;
  }
}

@media screen and (max-width: $desktop) {
  .welcome {
    .content.wide.fullPage {
      padding: 0.5rem;
    }
  }
}

@media screen and (max-width: $mobile) {
  .welcome {
    .content.wide.fullPage {
      padding: 0 2.5rem;
    }
  }
}

@media screen and (max-width: $extraLargeDesktop_4600) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 56%, #fff 44%);
  }
}

@media screen and (max-width: $extraLargeDesktop_4000) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 57%, #fff 43%);
  }
}

@media screen and (max-width: $extraLargeDesktop_3500) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 58%, #fff 42%);
  }
}

@media screen and (max-width: $extraLargeDesktop_3100) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 59%, #fff 41%);
  }
}

@media screen and (max-width: $extraLargeDesktop_2700) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 60%, #fff 40%);
  }
}

@media screen and (max-width: $extraLargeDesktop) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 61%, #fff 39%);
  }
}

@media screen and (max-width: $mediumLargeDesktop) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 63%, #fff 37%);
  }
}

@media screen and (max-width: $largeDesktop) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 66%, #fff 34%);
  }
}

@media screen and (max-width: $smallLargeDesktop) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 68%, #fff 32%);
  }
}

@media all and (max-width: $largeDesktop_1380) {
  .inputComponent__field {
    min-width: auto;
  }
}

@media screen and (max-width: $desktop) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 70%, #fff 30%);
  }
}

@media screen and (max-width: $smallDesktop) {
  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 60%, #fff 40%);
  }
}

@media screen and (max-width: $mobile) {
  .welcome {
    .content.wide.fullPage {
      padding: 0 2.5rem;
    }
  }
}
