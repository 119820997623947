.footer {
  &-container {
    color: #414042;
    margin: 0 auto;

    & .row {
      max-width: 1300px;
      margin: 0 auto;
      padding: 2rem;
    }
  }

  &-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #414042;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 68%;
    margin-top: 1rem;
    margin-right: 15rem;

    & .footer-link {
      margin-right: 1em;

      & a {
        color: #414042;
        line-height: 2rem;
      }

      &-text {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-right: 4rem;
        color: #414042;
        font-weight: bold;
        justify-content: space-between;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
    }
  }
}
