@import '../constants/constants.scss';

$button-orange: #ff5722;
$button-orange-dark: #e84e1d;
$button-orange-hover: #ff4409;
$button-orange-dark-hover: #d94516;

$button-blue: #1a237e;
$button-blue-dark: #0b1259;
$button-blue-hover: #151c6b;
$button-blue-dark-hover: #0b1154;

$button-red: #a83131;
$button-red-hover: #8d2929;

$button-default: #828282;
$button-default-hover: #6d6d6d;

$btn-min-width: 160px;

.button {
  padding: $space-md;
  text-align: center;
  min-width: $btn-min-width;
  border-radius: 30px;
  transition: 0.3s ease;
  font-size: $font-size-md;
  color: #fff;
  border: none;
  font-weight: 400;

  &--secondary {
    background: linear-gradient($button-orange, $button-orange-dark);
    color: #fff;

    &:hover {
      background: linear-gradient(
        $button-orange-hover,
        $button-orange-dark-hover
      );
    }
  }

  &--primary {
    background: linear-gradient($button-blue, $button-blue-dark);
    color: #fff;

    &:hover {
      background: linear-gradient($button-blue-hover, $button-blue-dark-hover);
    }
  }

  &--danger {
    background-color: $button-red;
    color: #fff;

    &:hover {
      background-color: $button-red-hover;
    }
  }

  &--default {
    background-color: $button-default;
    color: #fff;

    &:hover {
      background-color: $button-default-hover;
    }
  }

  &--icon {
    width: auto;
    min-width: auto;
    background-color: #414042;
    border-radius: 5px;
    padding: 5px 8px;
  }

  &--info {
    width: 100%;
    background-color: #fff;
    color: #000;
    margin: 0.5rem 0 0.5rem 0;
    padding: 1.5rem 2rem;
    margin-right: 0;
    text-align: left;
    font-size: 1rem;
    border: 0;

    &:hover {
      color: #867d7d !important;
      font-size: 1.1rem;
    }
  }

  &--round {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background-color: #2aad45;
    border: 1px solid #ddd;
    padding: 0;
    min-width: 0;

    span {
      color: #fff;
      font-size: 3em;
      font-weight: 800;
    }
    &:hover {
      opacity: 0.8;

      span {
        opacity: 1;
      }
    }
  }

  &--option {
    background-color: #eee;
    min-width: auto;
  }

  &--lg {
    padding: $space-lg;
    font-size: $font-size-lg;
  }

  &--sm {
    padding: $space-sm;
    font-size: $font-size-sm;
  }

  &[disabled] {
    opacity: $disabled-opacity;
    cursor: not-allowed;

    &:hover {
      opacity: $disabled-opacity !important;
    }
  }

  &:active {
    opacity: $active-opacity;
  }
}
