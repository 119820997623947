.popover {
  padding: 0 !important;
  border: none !important;
}

.popover > .arrow:after {
  border-right-color: #5d5d5d !important;
  border-left-color: #5d5d5d !important;
}

.popover > .popover-content {
  background: #5d5d5d;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 9px 14px;
}
