.titleComponent {
  margin: 2em 0 1em;
  color: #414042;
  font-weight: 700;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.red {
  color: #ff5a00;
}

.center {
  text-align: center;
}
