.delete-account {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.delete-link {
  border: none;
  background-color: transparent;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.abc-delete-button {
  display: flex;
  flex-direction: column;
}
