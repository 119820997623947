.changelog-table {
  min-width: 600px;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid black;

  tr {
    height: 30px;
  }

  td,
  th {
    border: 1px solid black;
    text-align: left;
    padding: 5px;
  }
}

.changelog-title {
  margin-bottom: 20px;
}
