@import '../../../../../../styles/mixins';
@import '../../../../../../styles/media-queries';

.desktopMenu {
  display: block;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  width: 100%;

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    cursor: pointer;
    width: auto;
    padding: 0 2rem;
    @include label();
    font-size: 1.25rem;
    line-height: 4.5rem;
    display: inline-block;
    margin-left: 0;

    &--languageSelector {
      cursor: auto;
    }

    &--right {
      margin-left: auto;
    }

    &--right ~ &--right {
      margin-left: 0;
    }

    .caret {
      margin-left: 0.5rem;
    }

    &:hover {
      text-decoration: underline;
      .desktopSubMenu {
        display: block;
        & span:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__itemLink {
    &--icon {
      display: inline-block;
      height: 2.5rem;
      width: 2.5rem;
      vertical-align: middle;
    }
  }

  &__itemLinkIcon {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background: yellow;
  }
}

.desktopSubMenu {
  display: none;
  position: absolute;
  left: 0;
  top: 4.5rem;
  min-width: 100%;
  z-index: 25;
  padding-bottom: 1rem;
  width: 200%;

  &__list {
    float: left;
    display: flex;
    flex-direction: column;
    background: white;
    padding-bottom: 1rem;
    box-shadow: 0px 10px 10px 0 rgba(50, 50, 50, 0.15);
  }

  &__link,
  &__link-disabled {
    padding: 0 2rem;
    @include label();
    line-height: 2rem;
  }

  &__link-disabled {
    cursor: default;
    color: #d8d8d8;
  }

  &--expand-left {
    right: 0;
    left: auto;
  }
}

@media screen and (max-width: $tablet) {
  .desktopMenu {
    display: none;
  }
}
