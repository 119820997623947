@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.createNewAppForm {
  .formInput {
    max-width: 1200px;
    width: auto;
    float: none;
    margin: 2rem 0;
    &__checkbox {
      display: flex;
      text-align: left;
      border: none;

      .legalTextButton {
        text-decoration: underline;
        font-weight: 400;
        cursor: pointer;
      }
    }
    label {
      font-weight: 400;
      font-size: 14px;
      margin: 0 !important;
    }

    .error-text {
      color: #960000;
    }

    .inputComponent__field {
      font-family:
        Roboto,
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    }

    .dropdown {
      .inputComponent__field {
        color: $myuplink-text-color;
      }
    }

    .inputComponent__placeholder {
      padding: 0.7rem 0 0 0.5em;

      &.focused {
        padding: 0 0 0 1em;
      }
    }

    .inputComponent--has-error {
      .inputComponent__error {
        white-space: pre-wrap;
        text-align: left;
      }
      .inputComponent__placeholder {
        padding: 1.25rem 0 0 1.5em;

        &.focused {
          padding: 0 0 0 1em;
        }
      }
    }

    &__checkbox {
      text-align: left;
      margin: 0;

      .checkboxLabel {
        margin-top: 3px;
      }
    }

    button {
      padding: 10px 80px;
      background-color: #ff5722;
      font-weight: 300;
    }
  }

  textarea {
    padding: 0.75rem 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin-bottom: -10px;
  }
}

.back-button {
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

#apps {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: -10px;
}
