$checkboxWidth: 30px;
$checkColor: #92c8d6;
$borderColor: #bbb;
$verticalPadding: 7px;
$horizontalPadding: 0px;

.checkboxComponent {
  label:before {
    content: '';
    -webkit-appearance: none;
    background-color: white;
    padding: $checkboxWidth/2 - 1;
    border: 1px solid $borderColor;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    // cursor: pointer;
    margin: $checkboxWidth/4;

    &:disabled {
      cursor: not-allowed;
    }
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: $verticalPadding * 1.5 + ($checkboxWidth * 0.2);
    left: $checkboxWidth/3 - 2;
    width: $checkboxWidth/2 - 2;
    height: $checkboxWidth - 10;
    border: solid $checkColor;
    border-width: 0px $checkboxWidth/5 $checkboxWidth/5 0;
    transform: rotate(45deg);
  }

  &--bottom {
    label:before {
      vertical-align: bottom;
      margin: $checkboxWidth/4 $checkboxWidth/2 0 0;
    }
  }

  &--underline {
    .checkboxComponent__text {
      text-decoration: underline;

      &_disabled {
        color: gray;
        cursor: not-allowed;
      }
    }
  }

  &--thin {
    .checkboxComponent__label {
      padding: 0;
    }

    label:before {
      vertical-align: bottom;
      margin: 3px $checkboxWidth/2 0 0;
    }

    input:checked + label:after {
      top: $verticalPadding * 1.5 + ($checkboxWidth * 0.2) - 9;
    }
  }

  &--error {
    label:before {
      border: 1px solid #960000;
    }

    .checkboxComponent__text {
      color: #960000;
    }
  }

  &__label {
    padding: 7px 0px;
    position: relative;
    cursor: pointer;
    font-size: 1em;
    white-space: pre-line;
    user-select: none;

    &_disabled {
      cursor: not-allowed;
    }
  }

  &__input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  &__children {
    display: inline-block;
    text-decoration: underline;
  }
}
