@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.modal-open .acceptedAgreementsForm {
  position: relative;
  transform: none;
  margin: 2rem;
  padding-bottom: 0;
  margin-bottom: 10px;
  top: 0;
  h2 {
    margin: 0px 10px 30px 10px;
  }
  h4 {
    margin: 30px 0 0 15px;
  }
  .formInput {
    border: none;
  }
  .modal-footer {
    border-top: none;
    text-align: center;
  }
  .formWrapper--spacer {
    margin-top: 0;
    height: 10px;
  }
}

.Login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .abc-login {
    align-items: flex-start;
  }

  .abc-login-tos {
    color: #585858;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
}

.loginForm,
.forgotPasswordForm {
  padding-right: 0;
  .form__heading {
    text-align: left;
    padding-top: 20px;
    margin: 0;
    font-size: 28px;
    font-weight: 800;
  }

  .form__text {
    margin: 0;
    text-align: left;
    padding-top: 1rem;
    font-size: 1rem;
  }

  .formInput {
    float: none;
    &__checkbox {
      display: flex;
      text-align: left;
      border: none;

      .legalTextButton {
        text-decoration: underline;
        font-weight: 700;
        cursor: pointer;
      }
    }

    .dropdown {
      .inputComponent__field {
        color: $myuplink-text-color;
      }
    }

    .inputComponent__placeholder {
      padding: 0.7rem 0 0 1.5em;

      &.focused {
        padding: 0 0 0 1em;
      }
    }

    .inputComponent--has-error {
      .inputComponent__error {
        color: #a83131;
        white-space: pre-wrap;
        margin: 7.7px 0 7.7px 0;
        text-align: left;
        font-weight: 400;
      }
      .inputComponent__placeholder {
        padding: 1.25rem 0 0 1.5em;

        &.focused {
          padding: 0 0 0 1em;
        }
      }
    }

    &__checkbox {
      text-align: left;
      margin: 0;
    }
  }

  .text-danger {
    color: #a83131;
    white-space: pre-wrap;
    margin: 7.7px 0 7.7px 0;
    text-align: left;
    font-weight: 400;
  }
}

.promo-text {
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  margin: 0 4rem;
  text-align: left;
  padding: 1rem;
  font-size: 1rem;
}
.loginForm,
.acceptedAgreementsForm {
  &__heading {
    text-align: left;
    padding: 1rem 1rem 0 1rem;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 800;
  }
  &__paragraph {
    margin: 0;
    text-align: left;
    padding: 1rem 0 0 1rem;
    font-size: 1rem;

    &--gray {
      color: $font-color;
    }
  }

  &__link {
    display: inline;
    cursor: pointer;
    font-weight: 600;
    color: $myuplink-text-color;
  }

  &__inputWrapper {
    margin: 1rem;
  }

  .formInput__checkbox {
    float: none;
    border-radius: 3px;
    border: 2px solid #ccc;
    display: flex;

    .inputComponent__field {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .inputComponent__placeholder {
      padding: 0.7rem 0 0 1.5em;

      &.focused {
        padding: 0 0 0 1em;
      }
    }

    .inputComponent--has-error {
      .inputComponent__placeholder {
        padding: 1.25rem 0 0 1.5em;

        &.focused {
          padding: 0 0 0 1em;
        }
      }
    }

    .legalTextButton {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }

  &__buttonWrapper {
    margin-top: 1rem;
    padding-bottom: 0;

    .button {
      background: linear-gradient(#1a237e, #0b1259);
      font-size: 1.2rem;
      font-weight: 400;
      padding: 16px 40px;
      min-width: 160px;
      width: 100%;
      text-align: center;
      border-radius: 30px;
      transition: 0.3s ease;
      color: #fff;
      border: none;
    }
  }

  &__forgotPasswordWrapper {
    margin-top: 0.5rem;
  }
}

.migration-configuration-popup {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
  text-align: center;

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 17px;
  }
  h4 {
    margin: 20px 0 -20px 0;
  }
  .popup-body {
    font-size: 1.5em;
    font-weight: 200;
  }
  .popup-bottom-panel {
    margin-top: 40px;
  }
}

.migration-property-selector button {
  font-weight: 200;
}

.migration-configuration-popup > * {
  margin: 4px;
  width: 100%;
}

.acceptedAgreementsButtons {
  text-align: center !important;
}

form {
  .form__link {
    border: none;
    background: none;
    padding-right: 0;
    line-height: 2;
    font-size: 16px;
    padding: 0.4rem 0rem;
    color: #414042;

    &:hover {
      color: #414042;
    }
  }
  .forgot-link-wrapper {
    text-align: right;
  }

  .form__paragraph {
    color: #414042;
    padding-top: 0;
    margin: 1.2rem 0;
    text-align: center;
  }
}

.button-wrapper--large {
  margin-top: 3rem;
  padding-bottom: 3rem;

  .button--secondary,
  .button--primary {
    padding: 16px 40px;
    text-align: center;
    min-width: 160px;
    width: 100%;
    border-radius: 30px;
    transition: 0.3s ease;
    font-size: 1.2rem;
    color: #fff;
    border: none;
    font-weight: 500;
    display: block;
  }
}

.login-ab2c-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.abc-login-title {
  font-size: 32px;
  font-weight: 800;
}

@media screen and (max-width: $tablet) {
  .promo-text {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: $mobile) {
  .loginForm,
  .acceptedAgreementsForm {
    top: 50%;
    margin: 2rem 0 0 2rem;
  }
  .promo-text {
    margin: 1rem 0 0 2rem;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
}
