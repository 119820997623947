@import '../../styles/media-queries.scss';

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

.inputComponent {
  position: relative;

  textarea {
    resize: none;
  }

  .inputComponent__placeholder__label {
    position: 'relative';
    width: 100%;
    margin: 0;
  }

  &__field {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.5em;
    font-weight: 400;

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
    }

    &:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }

    &--error {
      border: 2px solid #960000;
      padding: 20px 20px 10px 20px;
    }
  }

  &__label {
    margin-bottom: 0px;

    &--left {
      margin-left: 10px;
      float: left;
    }
  }

  &__placeholder {
    left: 0;
    color: #999;
    padding: 1.2em 0 0 1.5em;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition:
      font-size 200ms,
      padding 200ms;
    z-index: 1;
    font-size: 1.2em;

    &.focused {
      font-size: 0.625rem;
      padding-top: 0;
      padding-left: 1rem;
    }
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }

  @media only screen and (max-width: $tablet) {
    &__field {
      font-size: 1em;
    }

    &__error {
      font-size: 0.75em;
    }

    &__placeholder {
      padding: 0.5em 1em;
    }
  }

  @media screen and (max-width: $mobile) {
    &__error {
      font-size: 0.75em;
    }
  }
}
