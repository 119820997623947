@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/media-queries.scss';

.slide {
  padding: 0 4rem;
  margin: 0 auto;
  min-width: 670px;
  position: relative;

  &-inner {
    box-shadow: 0 2px 18px 0 rgba(72, 72, 72, 0.5);
    min-width: 716px;
    width: 100%;
    height: 480px;
    overflow: hidden;
    position: relative;
  }

  &-content {
    display: flex;
    left: 0;
    background: #fff;

    .content {
      text-align: center;
      width: 372px;
      height: 480px;
      padding: 22px;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-weight: 400;
        font-size: 28px;
        margin-top: 2rem;
        line-height: 2.5rem;
      }

      .slide-text {
        font-size: 24px;
        line-height: 1.3;
        font-weight: 400;
        margin: 40px auto;
      }
    }
  }

  &-text {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    margin: 40px auto;
  }
  &--active {
    left: 0;
  }

  &--prev {
    left: calc(-100% - 1px);
  }

  &--next {
    left: calc(100% + 1px);
  }

  .image {
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  &__contentWrapper {
    max-width: 75%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__text {
    @include label();
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  }

  &__buttonWrapper {
    padding: 1rem;
    display: flex;
    flex-direction: row;
  }

  &__contentWrapper {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      text-align: center;
      width: 372px;
      height: 494px;
      padding: 22px;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-weight: 400;
        font-size: 28px;
        margin-top: 2rem;
        line-height: 2.5rem;

        span {
          outline: none;
        }
      }
    }
  }
}

@media all and (max-width: $largeDesktop) {
  .slide {
    &__contentWrapper {
      .content h3 {
        font-size: 24px;
      }
    }

    &-text {
      font-size: 18px;
      margin: 20px auto;
    }
  }
}

@media all and (max-width: $mediumDesktop) {
  .slide {
    &__contentWrapper {
      .content {
        padding: 1rem;

        h3 {
          margin: 0;
        }
      }

      .slide-text {
        margin: 20px auto;
      }

      .buttons-item {
        width: 10rem;

        a {
          margin-left: 0;
          margin-bottom: 6px;
        }
      }
    }
  }

  .signed-out-layout-wrapper {
    background-image: linear-gradient(90deg, #dfdfdf 100%, #fff 0%) !important;
  }

  .welcome {
    &-content {
      display: block !important;
    }

    .content.fullPage {
      height: auto;
      width: auto;
    }

    .content.narrow {
      width: auto;
      margin: 60px auto 0;
      padding-bottom: 2rem;
      padding-top: 5rem;

      &.fullPage {
        margin-top: 4rem !important;
      }

      form {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    .slide {
      margin-top: 40px;

      &-control {
        top: 42%;

        &.right {
          right: -10px;
        }

        &.left {
          left: -10px;
        }
      }

      &-inner {
        box-shadow: none;
      }
    }
  }
}

@media all and (max-width: $tablet) {
  .slide {
    .content {
      padding: 2em;
    }
  }

  .signed-out-layout-wrapper {
    min-height: 450px;
  }
}
