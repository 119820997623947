@import '../../styles/mixins';

.auth {
  @include contentLayoutWrapper();
  margin-top: 20px;
  font-size: 16px;
  padding: 0 2rem 50px 2rem;

  &__wrapper {
    margin: 3em 0 1em;
  }

  table {
    margin: 2rem 0;
  }

  td {
    padding: 8px;
  }
}

h1#auth {
  margin-bottom: 0;
}
