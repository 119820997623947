@import '../../../styles/mixins';
@import '../../../styles/media-queries';

.footerMenu {
  display: block;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    list-style-type: none;
    padding-inline-start: 0;
    margin-top: 1rem;
  }

  &__item {
    position: relative;
    cursor: pointer;
    width: auto;
    @include label();
    font-size: 18px;
    font-weight: 300;
    line-height: 2rem;
    display: inline-block;
    margin-right: auto;
    text-align: left;

    &:first-child {
      padding: 8px 0;
    }

    &-heading {
      font-size: 1.2rem;
      line-height: 2rem;
      margin-right: 4rem;
      color: #414042;
      font-weight: 700;
      justify-content: space-between;
    }

    a:link,
    a:visited,
    a:hover {
      color: #414042;
    }

    .caret {
      margin-left: 0.5rem;
    }
  }

  .footerMenu__copyright {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    list-style-type: none;
    padding-inline-start: 0;
  }

  &-copyright {
    color: #a6a6a6;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    margin-left: auto;

    &.row {
      justify-content: flex-end;
    }
  }
}
