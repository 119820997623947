$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import './bootstrap-custom';

@import './fonts.css';
@import './variables.scss';
@import './media-queries.scss';

/* Styles */

html,
body {
  margin: 0 auto;
  font-size: 16px;
}

body {
  font-family:
    Roboto,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4em;
  color: #414042;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:
    Roboto,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
}

footer {
  background-color: #f4f4f4;
  font-size: 2em;
  color: #5b6367;
  width: 100%;
}

.lowercase {
  text-transform: lowercase;
}

/* login, register */

.entry {
  //should create common wrapper with max-width etc..
  max-width: 800px;
  margin: 0 auto;

  &__wrapper {
    margin-top: 150px;
  }
}

.formWrapper {
  &--center {
    margin: 0 auto;
  }

  &--margin-top {
    margin-top: 15px;
  }

  &--spacer {
    float: left;
    width: 100%;
    height: 20px;
  }
}

.formInput {
  float: left;

  &--half {
    width: calc(50% - 40px);
  }

  &__field {
    width: 100%;
    padding: 15px 30px;
    font-size: 1.5em;
  }

  &__button {
  }

  &__checkbox {
  }

  &__label {
    margin-bottom: 0px;

    &--left {
      margin-left: 10px;
      float: left;
    }
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }
}

@media only screen and (max-width: $smallDesktop) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: $tablet) {
  html {
    font-size: 12px;
  }

  .formWrapper {
    &--spacer {
      display: none;
    }
  }

  .formInput {
    margin: 0px 10px 20px 10px;

    &--half {
      width: calc(50% - 20px);
    }

    &__button {
      width: calc(100% - 40px);
      margin-top: 0px;
      text-align: center;

      .button {
        float: none;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  html {
    font-size: 10px;
  }

  .formInput {
    margin: 0px 20px 20px 20px;

    &--half {
      width: calc(100% - 40px);
    }

    &__button {
      margin-top: 30px;
    }

    &__checkbox {
      margin: 0px 20px 0px 20px;
    }
  }
  .system-info-wrapper {
    width: calc(100% - 1rem);
  }
}

@media screen and (max-width: $tiny) {
  html {
    font-size: 8px;
  }
}

/* home */

.welcome-page {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .forgotPasswordForm {
    min-height: 480px;
    width: 100%;
  }
}

.welcome .row {
  display: none;
}

.welcome .container-fluid {
  padding: 0;
}

.welcome .text {
  width: 450px;
  height: 100px;
  margin: 0 auto;
  padding: 0;
}

.welcome .text span {
  font-size: 2em;
  line-height: normal;
  color: #fff;
}

.welcome .buttons {
  width: 500px;
  margin: 0 auto;
  padding: 0;
}

.signed-in {
  min-height: 100vh;
}

.btn {
  font-size: inherit !important;
  color: #fff;
  border-radius: 30px;
  border: 0;
  padding: 10px 50px;
}

.btn.btn-login {
  background-color: $btn-login;
}

.btn.btn-register {
  background-color: $btn-register;
}

.btn.btn-login:active:focus,
.btn.btn-login:hover {
  background-color: $btn-login;
  opacity: 0.7;
}

.btn.btn-register:active:focus,
.btn.btn-register:hover {
  background-color: $btn-register;
  opacity: 0.7;
}

.buffer {
  margin-top: 60px;
  clear: both;
}

.button-wrapper {
  margin: 30px 0;
  display: flex;
}

.button {
  padding: 16px 40px;
  text-align: center;
  min-width: 160px;
  border-radius: 30px;
  transition: 0.3s ease;
  font-size: 1.2rem;
  color: #fff;
  border: none;
  font-weight: 400;
  cursor: pointer;

  &--right {
    float: right;
  }

  &--left {
    float: left;
  }

  &--primary {
    background: linear-gradient(#1a237e, #0b1259);
  }
  &--default {
    background-color: $btn-default;
  }
  &--secondary {
    background: linear-gradient(#ff5722, #e84e1d);
  }
  &--warning {
    background-color: $btn-remove;
  }

  &:active:focus:hover {
    opacity: 0.7;
  }
}

.form-footer {
  margin-top: 30px;
}

p a {
  color: $font-color;
  text-decoration: underline;

  &:hover {
    color: $font-color-highlighted;
  }
}

.badge-success {
  background-color: #eea236;
}

.page-centered {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2rem;
}

.row {
  max-width: 1300px;
  margin: 0;
}

.page-content {
  max-width: 1300px;
  margin: 0 auto;
}

pre {
  background-color: transparent;
  border: none;
}
