/* Variables */

$bg-welcome: #ebedef; //#75B6C8;
$bg-footer: #25505e;
$font-color-footer: #fff;
$btn-login: #00639a;
$btn-register: #00963f;
$btn-default: rgb(185, 185, 185);
$btn-remove: rgba(255, 0, 0, 0.7);
$font-color: #333;
$font-color-highlighted: #555;
$font-color-welcome: #656565;
$font-color-footer-link: #fff;
$toggle-switch-color: #2ea345;
$myuplink-text-color: #30596c;
$policy-link-color: #1a237e;
$black: #000;
