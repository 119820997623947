@import '../../styles/mixins';

.nibe-parameters {
  @include contentLayoutWrapper();
  font-size: 16px;
  padding: 0 2rem 50px 2rem;

  .row {
    margin-top: 20px;
    margin-bottom: 100px;
  }

  th {
    width: auto;
    padding: 8px;
    background: #e8eaed;
  }

  tr {
    width: auto;
    background: transparent;
    padding: 8px;
  }

  td {
    width: 100px;
    padding: 8px;
  }

  &__wrapper {
    margin: 3em 0 1em;
  }
}

h1#parameters {
  margin-bottom: 0;
}

#settingsTable,
#dataTable {
  min-width: 80%;

  th {
    padding: 8px;
    background: linear-gradient(#0000, #ecedee);
    border-top: 1px solid #9d9d9d;
  }

  td {
    font-weight: 400;
  }

  tr {
    border: 1px solid #454545;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}
