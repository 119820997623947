.fade-bg {
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 9999;
}
