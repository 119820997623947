#bootstrap-overrides {
  pre.version {
    background-color: transparent;
    border: none;
  }

  .swagger-ui .auth-btn-wrapper {
    gap: 10px;
  }
}
