@import '../../styles/mixins';

.intro {
  @include contentLayoutWrapper();
  margin-top: 20px;
  padding: 0 2rem 50px 2rem;

  th {
    padding: 8px;
    background: linear-gradient(#0000, #ecedee);
    border-top: 1px solid #9d9d9d;
  }

  tr {
    background: white;
    padding: 8px;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
  }

  td {
    padding: 8px;
  }

  &__wrapper {
    margin: 3em 0 1em;
  }

  .fist-request-list {
    list-style-type: disc;
  }
}

.tabordion {
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: 100px;

  .apps-list {
    margin: 0;
    width: 100%;
  }

  .apps-heading {
    padding: 0 1rem;
  }
}

.tabordion .intro-section {
  display: block;
}

.tabordion .option-label {
  background: #e2e2e2;
  color: #414042;
  border: 1px solid #fff;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding: 25px 25px;
  position: relative;
  width: 300px;
  z-index: 100;

  &.header-tab {
    display: inline-block;
    width: 50%;
  }
}

.tabordion .tab-content {
  min-width: 80px;
  padding-left: 60px;
  width: calc(100% - 230px);
  font-size: 16px;
  line-height: 1.4em;

  code {
    color: white;
    background-color: #828282;
    font-family: 'Univers W01', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
  }
}

.tabordion .option-label.selected {
  background: #828282;
  color: #fff;
}

#errorTable,
#httpTable,
#langTable {
  width: 100%;

  th {
    padding: 8px;
    background: linear-gradient(#0000, #ecedee);
    border-top: 1px solid #9d9d9d;
  }

  td {
    font-weight: 400;
  }

  tr {
    border: 1px solid #454545;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}
