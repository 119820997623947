@import '../../../../styles/media-queries.scss';

.header {
  background-color: #fff;
  padding: 0 2rem;
  z-index: 0;
  width: 100%;

  &-inner {
    display: flex;
  }
}

@media all and (max-width: $tablet) {
  .header-inner {
    flex-direction: column;
    position: relative;
  }
}
