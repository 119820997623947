@import '../../styles/mixins';

.updates {
  @include contentLayoutWrapper();
  font-size: 16px;
  padding: 0 2rem 50px 2rem;

  .row {
    margin-top: 20px;
  }

  &__wrapper {
    margin: 3em 0 1em;
  }
}

h1#updates {
  margin-bottom: 0;
}
