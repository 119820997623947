@import '../../../../styles/variables';

.AppListItem {
  padding: 0 1rem 0 1rem;
  border-top: none;
  border-radius: 0 0 3px 3px;
  margin-bottom: 20px;

  & .editBtnContainer {
    display: flex;
    justify-content: space-between;
  }

  & .boxContainer {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 3px 3px;
    border-top: none;
  }

  & .appName {
    flex-grow: 1;
    margin: 1rem 0;
    min-width: 700px;

    .InputWithCopy {
      margin-right: -5px;
    }

    .copyBtn {
      margin: 0 5px 0 0;
      padding: 10px;
    }
    .hideBtn {
      margin: 0 5px 0 5px;
      padding: 10px;
    }
  }

  & .appDescription {
    flex-grow: 3;
    margin: 1em;
  }

  & .appCallbackUrl {
    flex-grow: 3;
    margin: 1em;
  }

  & .staticContainer {
    border: 1px solid #cccccc;
    border-radius: 0 0 3px 3px;
  }

  & .editBtn {
    display: inline-block;
    font-size: 16px;
    border: 0;
    color: #fff;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    height: 3em;
    width: 3em;
  }

  .editBtn:hover {
    opacity: 0.7;
  }

  .editAppInfo__savedMessage {
    margin: 1rem 5px;
    font-size: 1.2em;
    font-weight: 500;
    color: green;
  }

  & .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .overlay-wrapper {
      cursor: not-allowed;
      border-radius: 30px;

      .btn.disabled,
      .btn.disabled:focus-visible,
      &:focus-visible {
        pointer-events: none;
        outline: none;
      }
    }

    & .btn-success,
    .btn-success:hover,
    .btn-success:active {
      margin: 1rem;
      margin-left: auto;
      background-color: #ff5722;
    }

    & .btn-danger {
      margin: 1rem 0;
      background-color: #a83131;
    }
  }

  .inputWithCopyWrapper {
    display: flex;

    .InputWithCopy {
      flex-grow: 1;
    }

    .invalidateBtn {
      font-size: 18px;
      margin-left: 10px;
      padding: 10px;
      border: 0;
      width: 44px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
