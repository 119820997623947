@import '../../styles/mixins';

.fordeveloper {
  @include contentLayoutWrapper();
  margin-top: 20px;
  padding: 0 2rem 50px 2rem;

  &__wrapper {
    margin: 0em 0 1em;
  }

  .row {
    font-size: 16px;
    line-height: 1.8em;
    font-weight: 300;
  }
}

h1#fordeveloper {
  margin-bottom: 0;
}

.react-tabs__tab-panel--selected {
  max-width: 1200px;
  width: 70%;
  margin-left: 60px;
  min-width: 800px;
}

.react-tabs__tab--selected {
  border-radius: 0;
}

.react-tabs__tab-list {
  border: none;
}

.modal-dialog {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1rem 1.5rem;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;

    &::before,
    &::after {
      display: none;
    }
  }
}
