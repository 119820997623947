@import '../../../../styles/media-queries.scss';

.content {
  display: flex;
  align-items: center;
  padding: 2rem;

  &.wide {
    width: 70%;
    min-height: 465px;
  }

  &.narrow {
    width: 40%;
  }

  &.legal {
    background-color: #fff;
    width: 100%;
    padding: 0 2rem;
  }
}

@media screen and (max-width: $mediumDesktop) {
  .content {
    padding: 1rem 2rem;

    &.wide {
      width: 100%;
      padding: 0.1rem;
    }

    &.narrow {
      width: 100%;
    }
  }
}
