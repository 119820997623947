@import '../../../../../../../../styles/media-queries';

.hamburgerMenuItem:first-child .menu-items {
  border-left: none;
}

.hamburgerMenuItem {
  width: 33%;
  padding: 1rem 0 2rem 0;
  text-align: left;
  height: 100%;

  &:first-of-type {
    .menu-items {
      border-left: none;
    }
  }

  &__menuItemHeading {
    margin: 0.5rem 1rem;
    line-height: 25px;
    font-weight: bold;
    font-size: 1.5rem;
    color: black;
    padding-bottom: 5px;
  }

  &__menuItemList {
    padding-left: 3em;
    margin-top: 20px;
    margin-bottom: 10px;
    border-left: 1px solid lightgrey;
  }

  &__menuItem {
    font-size: 1.1rem;
    display: block;
    margin: 0.5rem 1rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }

  &__menuItemHeadingLink {
    color: #414042;
  }

  &__menuItemLink {
    color: gray;

    :hover {
      color: #414042;
    }
  }

  &__menuItemLink-disabled {
    cursor: default;
    color: #d8d8d8;
  }

  &--languageSelector {
    color: #333;
  }

  @media only screen and (max-width: $tablet) {
    width: 50%;
    padding: 0;

    &__menuItemList {
      margin: 15px 0 0 0;
      min-height: 8em;
      padding-bottom: 2rem;
      height: auto;
    }

    &__menuItemHeading {
      font-size: 2rem;
    }

    &__menuItem {
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: $mobile) {
    width: 100%;
    padding: 0;

    &__menuItemList {
      padding-left: 0;
    }

    &__menuItemHeading {
      margin: 0 auto;
      line-height: 3rem;
      font-weight: bold;
      font-size: 2.5rem;
      text-align: center;
      color: black;
    }

    &__menuItem {
      margin: 1rem 0;
      font-size: 2rem;
      text-align: center;
    }
  }
}
