.landing-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  padding-top: 100px;

  ul {
    li {
      margin: 20px 0;
    }
  }
}
