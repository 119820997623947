.swaggger-ui {
  div.wrapper {
    background-image: none !important;
    overflow: scroll;
    height: auto;
  }

  pre.version {
    background-color: transparent;
    border: none;
  }

  .auth-btn-wrapper {
    gap: 10px;
  }
}
